import styled from 'styled-components';

export const SeparatorLine = styled.p`
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 40px;
  margin-bottom: 15px;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  .alignNumber {
    text-align: right;
  }
  .alignDate {
    text-align: center;
  }
  h3 {
    margin-top: 20px;
  }

  .button-action {
    min-width: 90px;
    height: 40px;
    &[disabled] {
      cursor: not-allowed !important;
      pointer-events: all !important;
    }
  }

  .button-aceite {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    background: #57069e;
    color: #fff;
    width: 100%;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    border-radius: 0.25rem;
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .toogle-inline {
    .toggleButton {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }

  .tbody-margem tr td,
  .thead-margem tr th {
    min-width: 130px;
  }
  .precos {
    background-color: rgb(136, 80, 191);
  }
  .precos:nth-child(2n) {
    background-color: rgb(123, 66, 180) !important;
  }
  .MargemRef {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .table-input {
    th {
      text-align: right;
      font-weight: 400;
    }

    .css-13fq05f {
      border: none;
      border-right: 1px solid #ccc;
      border-radius: 0px;
    }
  }
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  .nf-type-arial {
    display: flex;
    flex-direction: column;
    height: 100%;

    .dfe-search-label {
      display: flex;
      height: 100%;
      align-items: end;
      padding-bottom: 1.5px;

      .dfe-label {
        font-size: 0.8571428571428571em;
        font-style: inherit;
        color: #6b778c;
        font-weight: 600;
        line-height: 1.3333333333333333;
        display: inline-block;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
        margin-bottom: 4px;
      }
    }

    .dfe-search-type {
      display: flex;
      justify-content: center;
      align-items: end;
      height: 100%;
      width: 100%;
      padding-bottom: 2px;

      button {
        height: 38px;
        width: 100%;

        border: none;
        border-radius: 0px;

        &:hover {
          background: #8850bf;
          color: #fff;
          transition: 0.3s;
        }

        &:focus {
          outline: 1px solid #8850bf;
        }
      }
    }
  }
`;

export const TableEmpty = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  h3 {
    font-size: 10pt;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
