import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Container, SeparatorLine } from './styles';
import api from '~/services/api';
import { Col, Row } from 'react-bootstrap';
import Loja from '~/components/Loja';
import { NFe } from './types';
import {
  BuscaParceiro,
  InputNumber,
  InputText,
} from '~/components/NovosInputs';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { motivoSchema, schema } from './validations/validation';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import FooterDefault from '~/components/FooterDefault';
import DefaultLoader from '~/components/DefaultLoader';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import Separator from '~/components/Separator';
import { formatCurrencyAsText } from '~/utils/functions';
import { format, parseISO } from 'date-fns';
import { LojaContext } from '~/context/loja';

export const CancelamentoNFeContent: React.FC = () => {
  const { loja } = useContext(LojaContext);
  const MySwal = withReactContent(Swal);
  const {
    register,
    control,
    reset,
    getValues,
    clearErrors,
    setError,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [lojas, setLojas] = useState<number[]>([1]);
  const [loader, setLoader] = useState<boolean>(false);

  const [nf, setNF] = useState<NFe>({} as NFe);
  const [tipoDfe, setTipoDfe] = useState<'nfe' | 'nfce'>('nfe');

  const [clearLoja, setClearLoja] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMs, setLoadingMs] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('Executar Cancelamento');
  const [tipoEmissao, setTipoEmissao] = useState<string>('Própria');

  const searchDFe = handleSubmit(async (formData) => {
    try {
      setLoading(true);
      const { serie, num_nf, cod_pessoa, num_pedido, dfe } = formData;

      let response;
      const pessoa = cod_pessoa?.value;
      if (dfe !== 'nfce') {
        response = await api.get('/nfe/cancelamento', {
          params: {
            cod_loja: lojas,
            num_serie: serie,
            num_nf,
            cod_pessoa: pessoa,
          },
        });
      } else {
        response = await api.get('/nfce/cancelamento', {
          params: {
            cod_loja: lojas,
            num_serie: serie,
            num_nf,
            num_pedido,
            cod_pessoa: pessoa,
          },
        });
      }

      const { data } = response;
      if (!data.data || data.data.length === 0) {
        toast.warning(
          'Nenhum registro encontrado conforme os critérios informados. Verifique se a NF já se encontra cancelada.',
        );
        return;
      }

      if (data.data.length > 1) {
        toast.warning(
          'Encontrado mais de um registro com os dados informados. Entre em contato com o suporte.',
        );
        return;
      }

      setIsDisabled(true);
      const nfData: NFe = data.data[0];
      setNF(nfData);

      // Chamar microsserviço para verificar se está na sefaz
      if (nfData.flg_sinc_sefaz && dfe !== 'nfce') {
        setLoadingMs(true);
        await api.post(`/nf/consulta/sefaz`, {
          cod_loja: nfData.cod_loja,
          cod_seq_nf: nfData.cod_seq_nf,
          num_chave_acesso: nfData.num_chave_acesso,
          tipo_operacao: nfData.tipo_operacao,
        });
      }

      if (dfe !== 'nfce') {
        setTipoEmissao('Própria');
        if (nfData.tipo_emissao === 0) {
          setTipoEmissao('Terceiros');
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingMs(false);
    }
  });

  const handleClear = useCallback(() => {
    setIsDisabled(false);
    reset({
      serie: '',
      num_nf: '',
      num_pedido: '',
      dfe: tipoDfe,
      cod_pessoa: '',
      motivo: '',
    });
    setLojas(lojas);
    setNF({} as NFe);
    setClearLoja(true);
    setLabel('Executar Cancelamento');
  }, [lojas, reset, tipoDfe]);

  const handleReset = useCallback(() => {
    setIsDisabled(false);
    reset({
      serie: '',
      num_nf: '',
      dfe: 'nfe',
      cod_pessoa: '',
      motivo: '',
    });
    setLojas(lojas);
    setNF({} as NFe);
    setClearLoja(true);
    setLabel('Executar Cancelamento');
    setTipoDfe('nfe');
  }, [lojas, reset]);

  const createPayLoad = useCallback(
    (motivo: string) => {
      if (tipoDfe !== 'nfce') {
        return {
          motivo,
          cod_seq_nf: nf.cod_seq_nf,
          cod_loja: nf.cod_loja,
          num_chave_acesso: nf.num_chave_acesso,
          tipo_operacao: nf.tipo_operacao,
          nf,
        };
      }
      const { num_cnpj, des_uf, parametro_nfe } = loja;
      /**
       * IMPORTANTE
       * @param ambiente - Proveniente da tabela parametro_nfe. Caso o valor seja 0 = Produção / Caso seja 1 = Homologação
       * @param tipoAmbiente - Ajustado conforme o ambiente. O tipoAmbiente segue a regra da SEFAZ - 1 = Produção / 2 = Homologação
       */
      const ambiente = parametro_nfe.tipo_ambiente === 0 ? 1 : 2;

      return {
        cod_loja: nf.cod_loja,
        cod_seq_pedido: nf.cod_seq_pedido,
        cod_seq_cupom: nf.cod_seq_cupom,
        ambiente,
        num_chave: nf.num_chave,
        motivo,
        num_protocolo: nf.num_protocolo,
        cnpj: num_cnpj,
        uf: des_uf,
        nf,
      };
    },
    [loja, nf, tipoDfe],
  );

  const handleSubmitCancel = useCallback(async () => {
    if (!nf.num_nf && !nf.cod_seq_pedido) {
      return toast.warning(
        'Pesquise a Nota Fiscal antes de realizar o cancelamento.',
      );
    }
    try {
      const motivo = getValues('motivo');
      const isValid = await motivoSchema.isValid({ motivo });

      if (!isValid) {
        setError('motivo', {
          type: 'manual',
          message:
            'Informe o motivo do cancelamento com pelo menos 15 caracteres.',
        });
        toast.warn(
          'Informe o motivo do cancelamento com pelo menos 15 caracteres.',
        );
        return;
      }

      const payLoad = createPayLoad(motivo);

      await MySwal.fire({
        title: `Cancelar Documento Fiscal`,
        text: `Esta ação irá cancelar o documento fiscal. Deseja continuar? \n
          Número do documento: ${nf.num_nf || nf.cod_seq_pedido}`,
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);

          let response;
          if (tipoDfe !== 'nfce') {
            response = await api.post(`/nfe/cancelar`, payLoad);
          } else {
            response = await api.post(`/nfce/cancelar`, payLoad);
          }

          const { data } = response;

          if (!data.success) {
            toast.warn(data.message);
          }
          toast.success(data.message);
          setLoader(false);
          handleReset();
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }, [MySwal, createPayLoad, getValues, handleReset, nf, setError, tipoDfe]);

  useEffect(() => {
    setValue('dfe', 'nfe');
  }, [setValue]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      <h3>Cancelamento de Nota Fiscal</h3>
      <SeparatorLine />
      <Row>
        <Row>
          <Col>
            <Loja
              resetLojas={clearLoja}
              disabled={isDisabled}
              onChange={(val: any) => {
                if (val.length === 0) {
                  setLojas([1]);
                } else {
                  setLojas(val);
                }
              }}
              selectedLoja={loja.cod_loja}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={2}>
            <div className="nf-type-arial">
              <div className="dfe-search-label">
                <label htmlFor="dfe-type" className="dfe-label">
                  Buscar por
                </label>
              </div>
              <div className="dfe-search-type" id="dfe-type">
                <button
                  type="button"
                  style={{
                    backgroundColor: `${tipoDfe === 'nfe' ? '#8850bf' : ''}`,
                    color: `${tipoDfe === 'nfe' ? '#fff' : ''}`,
                  }}
                  onClick={() => {
                    setTipoDfe('nfe');
                    setValue('dfe', 'nfe');
                  }}
                >
                  NFe
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor: `${tipoDfe === 'nfce' ? '#8850bf' : ''}`,
                    color: `${tipoDfe === 'nfce' ? '#fff' : ''}`,
                  }}
                  onClick={() => {
                    setTipoDfe('nfce');
                    setValue('dfe', 'nfce');
                  }}
                >
                  NFCe
                </button>
              </div>
            </div>
          </Col>
          <Col md={12} lg={tipoDfe !== 'nfce' ? 6 : 4}>
            <BuscaParceiro
              label="Parceiro"
              placeholder="Selecione..."
              name="cod_pessoa"
              register={register}
              isError={!!errors.cod_pessoa}
              control={control}
              customOptions={{
                buscarPor: { cliente: true, fornecedor: true },
                fields: ['cod_fornecedor'],
              }}
              changeSelected={(selected: any) => {
                clearErrors('cod_pessoa');
                setValue('cod_pessoa', selected);
              }}
              disabled={isDisabled}
            />
          </Col>
          {tipoDfe === 'nfce' && (
            <Col md={12} lg={2}>
              <InputNumber
                label="Nº Pedido"
                maxLength={9}
                disabled={isDisabled}
                max={31}
                min={1}
                placeholder="0"
                name="num_pedido"
                register={register}
                control={control}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  if (value.length <= 9) {
                    setValue('num_pedido', Number(value));
                    clearErrors('num_pedido');
                  }
                }}
                isError={!!errors.num_pedido}
              />
            </Col>
          )}
          <Col md={12} lg={2}>
            <InputText
              label="Série"
              maxLength={50}
              caseInput="upper"
              placeholder=""
              name="serie"
              disabled={isDisabled}
              register={register}
              control={control}
              onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setValue('serie', value);
                clearErrors('serie');
              }}
              isError={!!errors.serie}
            />
          </Col>
          <Col md={12} lg={2}>
            <InputNumber
              label={`${tipoDfe !== 'nfce' ? 'Nº NF' : 'Nº CFe'}`}
              maxLength={9}
              disabled={isDisabled}
              max={999999999}
              min={1}
              placeholder="0"
              name="num_nf"
              register={register}
              control={control}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                if (value.length <= 9) {
                  setValue('num_nf', Number(value));
                  clearErrors('num_nf');
                }
              }}
              isError={!!errors.num_nf}
            />
          </Col>
          <Col>
            <div
              style={{
                display: 'flex',
                gap: '0.625rem',
                marginTop: '1.78rem',
                justifyContent: 'end',
              }}
            >
              <CustomButtonNovo
                disabled={isDisabled}
                onClick={searchDFe}
                label="Pesquisar"
                icon={IoIosSearch}
                width="8.125rem"
                showLoading={loading}
              />
              <CustomButtonNovo
                disabled={loading}
                variant="cancel"
                label="Cancelar"
                icon={MdOutlineCancel}
                width="8.125rem"
                onClick={handleReset}
              />
              <CustomButtonNovo
                disabled={loading}
                variant="clear"
                label="Limpar"
                icon={MdOutlineCancel}
                width="8.125rem"
                onClick={handleClear}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Separator labelText="Documento" />
          </Col>
          <Col
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignContent: 'end',
            }}
          >
            {loadingMs ? (
              <small
                style={{
                  color: '#39bf34',
                }}
              >
                CONSULTANDO NFE NA SEFAZ
              </small>
            ) : (
              ''
            )}
          </Col>
          {nf && (nf.num_nf || nf.cod_seq_pedido) && (
            <Col style={{ background: '#ebfff3' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Emissão</th>
                    <th>Valor</th>
                    <th>Data Emissão</th>
                    <th>Nº Docto.</th>
                    <th>Parceiro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tipoDfe === 'nfe' ? 'NF-e' : 'NFC-e'}</td>
                    <td>{tipoEmissao}</td>
                    <td>R$ {formatCurrencyAsText(nf.val_total_nf)}</td>
                    <td>
                      {format(new Date(parseISO(nf.dta_emissao)), 'dd/MM/yyyy')}
                    </td>
                    <td>{nf.num_nf}</td>
                    <td>{nf.nome_pessoa || 'Não informado'}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          )}
        </Row>
        <Row>
          <Col xl={8} lg={12}>
            <InputText
              name="motivo"
              label="Motivo do Cancelamento"
              min={15}
              register={register}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                clearErrors('motivo');
                setValue('motivo', value);
              }}
              isError={!!errors.motivo}
              maxLength={512}
            />
          </Col>
          <Col>
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
                width: '100%',
                gap: '10px',
                marginTop: '1.875rem',
              }}
            >
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '23rem',
                  minWidth: '10rem',
                  height: '100%',
                }}
                className="button-action"
                variant="success"
                type="button"
                disabled={(!nf.num_nf && !nf.cod_seq_pedido) || loading}
                onClick={() => {
                  handleSubmitCancel();
                }}
              >
                <BsFillLightningChargeFill size={20} />
                {label}
              </Button>
            </div>
          </Col>
        </Row>
        <SeparatorLine
          style={{
            marginBottom: '0px',
          }}
        />
      </Row>
      <FooterDefault codTela={211} />
    </Container>
  );
};
